import { RunWasteType } from '~app/types/run';
import { RegionType } from '~type/service/geo/region';
import { OperationType } from '~type/service/utility/operation';


type NotDone = Omit<{ [k in keyof typeof OperationType.Status]: { icon: string, title: string } }, 'DONE'>;
export type OperationStepsType = NotDone & { [k in 'DONE']?: never };

export interface siteIds {
	readonly PRO: string
	readonly REP?: string
	readonly PART?: string
}

export interface RegionData {
	readonly iconRight: 'adjust' | 'hdr_weak';
	readonly ids: siteIds;
	readonly title: (type?: 'PRO' | 'REP' | 'PART') => string;
}

export interface RegionsSimulator {
	readonly FR_IDF: readonly RegionData[];
	readonly FR_LYON: readonly RegionData[];
	readonly 'FR_AIX-MARSEILLE-PROVENCE': readonly RegionData[];
}

/**\
 * Déchèteries
 */
export const landfills = [
	[`DERICHEBOURG IVRY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG NANTERRE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG NOISY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG CHATILLON`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG BONNEUIL`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG CORBEIL-ESSONNES`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG ARGENTEUIL`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG CHELLES`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG LIMAY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG NEMOUR`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG GENNEVILLIERS`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG LA COURNEUVE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PAPREC COURNEUVE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`VAREA IVRY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA NANTERRE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA PIERRELAYE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA LIMAY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA TRIEL SUR SEINE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA ACHERES`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`SMS ECOTRI L'HAY-LES-ROSES`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`COSSON PLATEFORME ECOTRI LA COURNEUVE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`SOLARZ LE BOURGET`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUXO BENNES 93450 SAINT DENIS`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUXO BENNES MASSY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUXO BENNES Vitry`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUXO BENNES PONT CARRE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUSOFER VITRY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`SADLOC VILLENEUVE LE ROI`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`BUTY SERVICES LYON`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`DERICHEBOURG (7EME)`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`DERICHEBOURG VAULX`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`ECOTRI LYON`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`SERFIM LYON (7EME)`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`SERFIM LYON (ST PRIEST)`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`MAT'ILD BOUC BEL AIR`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`MAT'ILD LA GARDE`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`MAT'ILD MARSEILLE 11`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`MAT'ILD MARSEILLE 15`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`MAT'ILD MEYRARGUES`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`PDB AIX`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`PDB ARNAVAUX`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`PDB AUBAGNE`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`PDB TOULON`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`PURFER DERICHEBOURG MARSEILLE 11`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`PURFER DERICHEBOURG MARSEILLE 14`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`PURFER FARLEDE`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`SILIM VEOLIA`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
];

export const providerNames = ['GIRALIFT', 'SABRI HLB', 'PZ TRANSPORT', 'GKTDE', 'AUTRE'];

export const operationSteps: OperationStepsType = {
	[OperationType.Status.STARTED]: {
		icon: 'phone',
		title: `Cordonnées client`,
	},
	[OperationType.Status.CUSTOMER_CONTACTED]: {
		icon: 'timer',
		title: `Temps de trajet`,
	},
	[OperationType.Status.TRAVEL_TIME_ADDED]: {
		icon: 'photo_camera',
		title: `Photos avant l'opération`,
	},
	[OperationType.Status.OPERATION_PICTURES_TAKED_BEFORE]: {
		icon: 'assignment_turned_in',
		title: `Conformité de l'opération`,
	},
	[OperationType.Status.OPERATION_FORECAST_CHECKED]: {
		icon: 'photo_camera',
		title: `Photos après l'opération`,
	},
	[OperationType.Status.OPERATION_PICTURES_TAKED_AFTER_OPTIONAL]: {
		icon: 'timeline',
		title: `Ajustement au réel`,
	},
	[OperationType.Status.OPERATION_CONTENT_ADDED]: {
		icon: 'euro',
		title: `Calcul du prix`,
	},
	[OperationType.Status.OPERATION_PRICE_ADDED]: {
		icon: 'receipt',
		title: `Validation du reçu`,
	},
	[OperationType.Status.OPERATION_VALIDATED]: {
		icon: 'photo_camera',
		title: `Photos après l'opération`,
	},
	[OperationType.Status.OPERATION_PICTURES_TAKED_AFTER]: {
		icon: 'photo_camera',
		title: `Photos du camion`,
	},
	[OperationType.Status.TRUCK_PICTURES_TAKED]: {
		icon: 'notes',
		title: `Détails interne`,
	},
	[OperationType.Status.INTERNAL_META_ADDED]: {
		icon: 'timer',
		title: `Temps de l'opération`,
	},
	[OperationType.Status.OPERATION_TIME_ADDED]: {
		icon: 'check',
		title: `Fin de l'opération`,
	},
};

export const simulatorByZone: RegionsSimulator = {
	FR_IDF: [
		{
			iconRight: 'adjust',
			title: type => `${type ? `[${type}] ` : ''}Paris et Intra Francilienne`,
			ids: { PRO: 'SI-8Z8DKE', REP: 'SI-925AKX', PART: 'SI-9DLDT2' },
		},
		{
			iconRight: 'hdr_weak',
			title: type => `${type ? `[${type}] ` : ''}Hors Francilienne`,
			ids: { PRO: 'SI-8Z8DTS', REP: 'SI-925AFY', PART: 'SI-9DLC2R' },
		},
	],
	FR_LYON: [
		{
			iconRight: 'adjust',
			title: type => `${type ? `[${type}] ` : ''}Lyon et petite couronne`,
			ids: { PRO: 'SI-96S9DX', REP: 'SI-96S9RY', PART: 'SI-9DLDV7' },
		},
		{
			iconRight: 'hdr_weak',
			title: type => `${type ? `[${type}] ` : ''}Grand Lyon`,
			ids: { PRO: 'SI-97UREL', REP: 'SI-97URLD', PART: 'SI-9DLDTL' },
		}
	],
	'FR_AIX-MARSEILLE-PROVENCE': [
		{
			iconRight: 'adjust',
			title: type => `${type ? `[${type}] ` : ''}Marseille`,
			ids: { PRO: 'SI-979KX4', REP: 'SI-979L66', PART: 'SI-9DLDE4' },
		},
		{
			iconRight: 'hdr_weak',
			title: type => `${type ? `[${type}] ` : ''}Aubagne, Vitrolles,Aix-en-Provence, La Ciotat`,
			ids: { PRO: 'SI-979L26', REP: 'SI-979KXR', PART: 'SI-9DLDU2' },
		},
		{
			iconRight: 'hdr_weak',
			title: type => `${type ? `[${type}] ` : ''}Toulon`,
			ids: { PRO: 'SI-9ACC2S', REP: 'SI-9ACC57', PART: 'SI-9DLDRU' },
		}
	],
} as const;

export const metaRegions: RegionType.Region[] = [
	{
		id: 'FR_IDF',
		name: 'Île-de-France',
		config: {
			timezone: 'Europe/Paris',
			country: 'FR',
			locale: 'fr-FR',
			currency: 'EUR',
			vat: 20.0,
		},
	},
	{
		id: 'FR_LYON',
		name: 'Lyon',
		config: {
			timezone: 'Europe/Paris',
			country: 'FR',
			locale: 'fr-FR',
			currency: 'EUR',
			vat: 20.0,
		},
	},
	{
		id: 'FR_AIX-MARSEILLE-PROVENCE',
		name: 'Marseille',
		config: {
			timezone: 'Europe/Paris',
			country: 'FR',
			locale: 'fr-FR',
			currency: 'EUR',
			vat: 20.0,
		},
	},
	{ // Région de test
		id: 'TEST',
		name: 'Test (Greater London)',
		config: {
			timezone: 'Europe/London',
			country: 'GB',
			locale: 'en-GB',
			currency: 'GBP',
			vat: 10.0,
		},
	},
];

export type isPartSimulation = Boolean;

export const o /*bfuscation*/ = () => '';
const defaultKey: string = '@eyJhbGciOiJIUzI1' + o() + 'NiIsInR5cCI6IkpXVCJ9' + o() + '.eyJpZCI6IkMtQTM3ODMiLCJwZXJtaXNzaW9ucyI6WyJAQ1V'
+ o() + 'TVE9NRVIiXSwic2NvcGUiOlsiTm9ybWFsIiwi' + o() + 'T3BlcmF0b3IiXSwiX3Y' + o() + 'iOjAsImlhdCI6MTcyMjI0'
+ o() + 'Mjk3OSwiZXhwIjo1MjkwNTU2Nzk2fQ.czqs' + o() + 'P88l9zwpwJ_ZQzSDNE_v' + o() + 'chamx2C6kN8iRC46PEg';
const partKey: string = 'eyJhbGciOiJIUzI1' + o() + 'NiIsInR5cCI6IkpXVCJ9' + o() + '.eyJpZCI6IkMtOURLVTc0IiwicGVybWlzc2lvbnMiOlsiQENV'
+ o() + 'U1RPTUVSIl0sInNjb3BlIjpbIk5vcm1hbCJdLC' + o() + 'JfdiI6MCwiaWF0Ijox' + o() + 'NzM3MDM2MzgyLCJl'
+ o() + 'eHAiOjE3Njg2NTg3ODJ9.JPjm' + o() + '77Dn5LVUil3R-XuBEG4ljexTu' + o() + 'ycorEQSw0PIkYU';

export function getSimulatorLink(siteId: string, isPart: isPartSimulation): string {
	const token = isPart ? partKey : defaultKey;

	return 'https://lesri' + o() + 'peurs' + o() + '.app/order/new/' + siteId + '/collect'
		+ '?goService=UTILITY'
		+ '&_feat=INTERNAL_UTILITY'
		+ '&_platform=WEB_IFRAME'
		+ '&_to' + o() + 'ken=' + o() + token;
}

export interface dialogData {
	title?: string,
	content?: string,
	contentIframeUrl?: string,
	contentLoader?: boolean,
	contentIcon?: string,
	buttons?: Array<{
		title: string,
		iconLeft?: string,
		iconRight?: string,
		click?: () => void,
	}>,
	close?: boolean,
	debug?: () => void,
	debugId?: string | null,
}
